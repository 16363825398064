<template>
  <n-space justify="space-between" :wrap="false" class="toolbar">
    <icon name="material-symbols:arrow-back-rounded" size="22px" class="mx-2 cursor-pointer w-6 h-6" @click="router.back()"/>
    <n-ellipsis line-clamp="1" class="pb-5 text-xl">
      <slot />
    </n-ellipsis>

    <slot name="cart">
      <NuxtLink :to="cartId ? '/cart/': ''">
        <n-badge :value="quantity" :offset="[-17, 17]">
          <icon name="material-symbols:shopping-bag-outline" class="w-6 h-6"/>
        </n-badge>
      </NuxtLink>
    </slot>
  </n-space>
</template>
<script setup lang="ts">
import {NSpace, NEllipsis, NBadge} from "naive-ui";
import {useCartStore} from "~/store/cart";
import {storeToRefs} from "pinia";
const { quantity, cartId } = storeToRefs(useCartStore())
const router = useRouter()

</script>

<style scoped>
svg {
  color: #000;
}

.toolbar {
  border-bottom: 1px solid #EDEDED;
  background: #FFF;
}
</style>

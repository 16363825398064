import type {GlobalThemeOverrides} from "naive-ui";

export const themeOverrides: GlobalThemeOverrides = {
    common: {
        primaryColor: "#000",
        fontSize: "16px",
        borderColor: "#000",
    },
    Input: {
        common: {
            fontSizeLarge: "16px"
        }
    },
    Button: {
        heightLarge: "50px",
        fontSizeLarge: "18px",
        fontWeight: "700",

        borderRadiusLarge: "6px",
        borderRadiusMedium: "6px",

        // color: "#000",
        // textColor: "#fff",


        colorPressed: "#fff",
        textColorPressed: "#000",
        colorFocus: "#000",
        textColorFocus: "#fff",
        colorDisabled: "#808080",
        textColorDisabled: "#000",

        rippleColor: "#fff",
        borderFocus: "#000",
        borderHover: "#000",

        colorPrimary: "#000",
        colorFocusPrimary: "#000",
        colorHoverPrimary: "#000",
        colorPressedPrimary: "#000",
        borderPressedPrimary: "#000",
        rippleColorPrimary: "#000",
        borderHoverPrimary: "#000",
        borderFocusPrimary: "#000",
        borderDisabledPrimary: "#000",

    }
}
<template>
  <NuxtLoadingIndicator/>
  <n-config-provider :theme-overrides="themeOverrides">
  <n-message-provider :duration="5000">
  <n-layout content-style="margin: 10px">
    <spinner v-if="loading" />
      <n-layout-header>
        <toolbar>
          <slot name="toolbar-title">
            Shop
          </slot>
        </toolbar>
        <slot name="header" />
      </n-layout-header>
      <n-layout-content>
        <slot />
      </n-layout-content>
      <n-layout-footer position="static">
        <div style="margin-top: 18px">
          <slot name="footer">
            <shop-footer />
          </slot>
        </div>
      </n-layout-footer>
  </n-layout>
  </n-message-provider>
  </n-config-provider>
</template>

<script lang="ts" setup>
import {
  NConfigProvider,
  NLayout,
  NLayoutContent,
  NLayoutHeader,
  NLayoutFooter,
  NMessageProvider,
} from "naive-ui"
import {themeOverrides} from "~/common/theme";
const nuxtApp = useNuxtApp();
const loading = ref(false);
nuxtApp.hook("page:start", () => {
  loading.value = true;
});
nuxtApp.hook("page:finish", () => {
  loading.value = false;
});



</script>

<style lang="scss" scoped>

@media (min-width: 1300px) {
  .container {
    width: 1300px;
    margin: auto;
  }
}

footer {
  width: 100%;
  display: block;
}

.container {
  padding-bottom: 5em;
  flex: 1;
}

.n-layout {
  min-height: 100vh;
}

</style>